<template>
  <div v-if="currentUser.superAdmin">
    <div class="w-300px pl-4 pb-4">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="shopCategories"
      :expanded.sync="expanded"
      :loading="loading"
      :disable-pagination="true"
      show-expand
      single-expand
      class="elevation-1"
      hide-default-footer
      @item-expanded="loadChildCategories"
    >
      <!-- :expanded.sync="expanded" -->
      <template v-slot:item.iconNormalPng="{ item }">
        <a v-bind:href="item.iconNormalPng" target="_blank">
          <v-img
            v-if="item != null && item.iconNormalPng != null"
            :src="item.iconNormalPng"
            contain
            max-height="25"
            max-width="25"
          >
          </v-img>
        </a>
      </template>
      <template v-slot:item.iconActivePng="{ item }">
        <a v-bind:href="item.iconActivePng" target="_blank">
          <v-avatar v-if="item != null && item.iconActivePng != null" color="grey">
            <v-img
              v-if="item != null && item.iconActivePng != null"
              :src="item.iconActivePng"
              contain
              max-height="25"
              max-width="25"
            >
            </v-img>
          </v-avatar>
        </a>
      </template>
      <template v-slot:item.iconNormalSvg="{ item }">
        <a v-bind:href="item.iconNormalSvg" target="_blank">
          <v-img
            v-if="item != null && item.iconNormalSvg != null"
            :src="item.iconNormalSvg"
            contain
            max-height="25"
            max-width="25"
          >
          </v-img>
        </a>
      </template>
      <template v-slot:item.published="{ item }">
        <v-switch v-model="item.published" :disabled="true"></v-switch>
      </template>
      <template v-slot:item.advertRelevant="{ item }">
        <v-switch
          v-model="item.advertRelevant"
          @click="changeAdvertRelevant(item, 'advertRelevant')"
        ></v-switch>
      </template>
      <template v-slot:item.createPostRelevant="{ item }">
        <v-switch
          v-model="item.createPostRelevant"
          @click="changePostRelevant(item, 'createPostRelevant')"
        ></v-switch>
      </template>
      <template v-slot:item.reorder="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'ShopCategoryEdit',
              params: { shopCategoryId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="changePublishAsk(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:header.add>
        <router-link :to="{ name: 'ShopCategoryCreate' }">
          <v-avatar color="teal" size="30" rounded>
            <v-icon dark>mdi-plus</v-icon>
          </v-avatar>
        </router-link>
      </template>
      <template v-slot:header.reorder>
        <router-link :to="{ name: 'shop-categories-reorder' }">
          <v-avatar color="indigo" size="30" rounded>
            <v-icon dark>flaticon2-sort</v-icon>
          </v-avatar>
        </router-link>
      </template>

      <!-- expand icon +/- -->
      <template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
        <v-icon @click="expand(!isExpanded)">
          {{ item.numberOfSubCategories > 0 ? (!isExpanded ? "$plus" : "$minus") : "" }}
        </v-icon>
      </template>

      <!-- expand item/row -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0.5em 0.5em 1em 1em">
          <v-data-table
            dense
            :headers="headers"
            :items="item.childCategories"
            :disable-pagination="true"
            class="elevation-2"
            hide-default-footer
          >
            <template v-slot:item.iconNormalPng="{ item }">
              <a v-bind:href="item.iconNormalPng" target="_blank">
                <v-img
                  v-if="item != null && item.iconNormalPng != null"
                  :src="item.iconNormalPng"
                  contain
                  max-height="25"
                  max-width="25"
                >
                </v-img>
              </a>
            </template>
            <template v-slot:item.iconActivePng="{ item }">
              <a v-bind:href="item.iconActivePng" target="_blank">
                <v-avatar v-if="item != null && item.iconActivePng != null" color="grey">
                  <v-img
                    v-if="item != null && item.iconActivePng != null"
                    :src="item.iconActivePng"
                    contain
                    max-height="25"
                    max-width="25"
                  >
                  </v-img>
                </v-avatar>
              </a>
            </template>
            <template v-slot:item.iconNormalSvg="{ item }">
              <a v-bind:href="item.iconNormalSvg" target="_blank">
                <v-img
                  v-if="item != null && item.iconNormalSvg != null"
                  :src="item.iconNormalSvg"
                  contain
                  max-height="25"
                  max-width="25"
                >
                </v-img>
              </a>
            </template>
            <template v-slot:item.iconActiveSvg="{ item }">
              <a v-bind:href="item.iconActiveSvg" target="_blank">
                <v-avatar v-if="item != null && item.iconActiveSvg != null" color="grey">
                  <v-img
                    v-if="item != null && item.iconActiveSvg != null"
                    :src="item.iconActiveSvg"
                    contain
                    max-height="25"
                    max-width="25"
                  >
                  </v-img>
                </v-avatar>
              </a>
            </template>
            <template v-slot:item.advertRelevant="{ item }">
              <v-switch
                v-model="item.advertRelevant"
                @click="changeAdvertRelevant(item, 'advertRelevant')"
              ></v-switch>
            </template>
            <template v-slot:item.createPostRelevant="{ item }">
              <v-switch
                v-model="item.createPostRelevant"
                @click="changePostRelevant(item, 'createPostRelevant')"
              ></v-switch>
            </template>
            <template v-slot:item.reorder="{ item }">
              <v-avatar color="teal" size="26" rounded>
                <router-link
                  :to="{
                    name: 'ShopCategoryEdit',
                    params: { shopCategoryId: item.id }
                  }"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </router-link>
              </v-avatar>
            </template>
            <template v-slot:item.add="{ item }">
              <v-avatar color="red darken-4" size="26" rounded>
                <v-icon dark @click="changePublishAsk(item)">mdi-delete</v-icon>
              </v-avatar>
            </template>
            <template v-slot:header.add>
              <router-link :to="{ name: 'ShopCategoryCreate' }">
                <v-avatar color="teal" size="30" rounded>
                  <v-icon dark>mdi-plus</v-icon>
                </v-avatar>
              </router-link>
            </template>
            <template v-slot:header.reorder>
              <router-link :to="{ name: 'shop-categories-reorder' }">
                <v-avatar color="indigo" size="30" rounded>
                  <v-icon dark>flaticon2-sort</v-icon>
                </v-avatar>
              </router-link>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "shopCategories",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.oobleeCategories");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("pages.shopCategories.order"),
          align: "start",
          sortable: false,
          value: "ord"
        },
        {
          text: this.$i18n.t("pages.shopCategories.name"),
          value: "name",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.categoryType"),
          value: "categoryType",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.advertRelevant"),
          value: "advertRelevant",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.createPostRelevant"),
          value: "createPostRelevant",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.iconNormalPNG"),
          value: "iconNormalPng",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.iconActivePNG"),
          value: "iconActivePng",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.iconNormalSVG"),
          value: "iconNormalSvg",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopCategories.published"),
          value: "published",
          sortable: false
        },
        { text: "", value: "reorder", width: "5%", sortable: false },
        { text: "", value: "add", width: "5%", sortable: false }
      ];
    }
  },
  data() {
    return {
      categoryName: "",
      cityName: "",
      shopCategories: [],
      totalShopCategories: 0,
      loading: true,
      dialogDelete: false,
      errors: [],
      countries: [],
      selectedCountry: null
    };
  },
  async created() {
    if (this.currentUser.superAdmin) this.retrieveShopCategories();
    this.getCountries();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.retrieveShopCategories();
    },
    async changePublishAsk(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.shopCategories.deleteMessage") + item.name + " ?"
        )
      ) {
        this.changePublish(item, false);
      }
    },
    // loadChildCategories({item}) {
    //     console.log("item", item)
    //     console.log("shopCategories", this.shopCategories)
    //     item.childCategories = this.shopCategories.filter(itm => itm.parentShopCategory !== null && itm.parentShopCategory.id === item.id)
    // },
    loadChildCategories({ item }) {
      this.loading = true;
      if (item.id != null) {
        ApiService.get(`api/shop-category/${item.id}/subcategories`)
          .then(response => {
            //this.$log.debug("Shop subcategories: ", response.data);
            console.log("subCats", response.data);
            item.childCategories = response.data;
            console.log("shopCategories", this.shopCategories);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    changePublish(item, publish) {
      this.loading = true;
      ApiService.patch(`api/shop-category/${item.id}`, {
        published: publish
      })
        .then(response => {
          this.$log.debug("Published: ", response.data.published);
          this.info = response.data.published;
          this.retrieveShopCategories();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeAdvertRelevant(item) {
      this.loading = true;
      ApiService.patch(`api/shop-category/${item.id}`, {
        isAdvertRelevant: item.advertRelevant
      })
        .then(response => {
          this.$log.debug("AdvertRelevant: ", response.data.advertRelevant);
          this.info = response.data.advertRelevant;
          this.loadChildCategories(item);
          this.retrieveShopCategories();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changePostRelevant(item) {
      this.loading = true;
      ApiService.patch(`api/shop-category/${item.id}`, {
        isCreatePostRelevant: item.postRelevant
      })
        .then(response => {
          this.$log.debug("Post Relevant: ", response.data.postRelevant);
          this.info = response.data.postRelevant;
          this.loadChildCategories(item);
          this.retrieveShopCategories();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.shopCategories.deleteMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteShopCategory(item.id);
    },
    deleteShopCategory(recommendedCategory) {
      this.loading = true;
      ApiService.delete(`api/shop-category/${recommendedCategory}`)
        .then(response => {
          this.$log.debug("Shop category deleted: ", response);
          this.retrieveShopCategories();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    clearSearch() {
      this.cityName = "";
      this.categoryName = "";
    },
    retrieveShopCategories() {
      const params = {};
      params["countryId"] = this.selectedCountry?.id || "";

      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shop-category", { params }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Shop Categories: ", response.data);
          this.shopCategories = response.data;
          this.totalShopCategories = response.data.length;
          this.loading = false;

          this.shopCategories = response.data.map(item => {
            return {
              childCategories: [],
              ...item
            };
          });

          let title = this.totalShopCategories != null ? this.totalShopCategories : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
